import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/kritische-reiseziele"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627 active-trail">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/reisedurchfall-vorbeugen/" title>
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/gesundheitsratschlage-fur-die-reise/" title>
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/kritische-reiseziele/"
                          title
                          className="active-trail active"
                        >
                          Kritische Reiseziele
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reisediarrhoe-behandlung/" title>
                          Behandlung von Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-12" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{ fontWeight: "bold", fontSize: "30px", lineHeight: "26px", margin: "20px 0 10px 0", fontFamily: "'Open Sans', sans-serif" }}>
                        REISE
                        <br />
                        APOTHEKE
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_reisen.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Was gehört in die Reise-
                        <br />
                        apotheke? Finden Sie hier die praktische Checkliste.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/gesundheitsratschlage-fur-die-reise/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Kritische Reiseziele
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/visual_weltkarte1.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Einschätzung des Reiserisikos
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                          Schätzungen besagen, dass bis zu 40
                          Prozent der Urlauber bei Reisen in tropische und
                          subtropische Gebiete von Durchfall betroffen sind. Reisedurchfall ist damit die 
                          Reiseerkrankung schlechthin.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <table
                                align="left"
                                border="0"
                                cellPadding="1"
                                cellSpacing="1"
                                style={{
                                  width: "100%"
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>
                                        Verantwortlich für Reisedurchfall sind
                                        meistens Bakterien, man spricht daher
                                        von bakterieller{" "}
                                        <a href="/gastroenteritis/">
                                          Gastroenteritis
                                        </a>
                                        . Die Infektion erfolgt durch
                                        verunreinigtes Wasser oder verunreinigte
                                        Lebensmittel.
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        Der Durchfall kann schwach sein und nach
                                        2 bis 3 Tagen abklingen. Es ist aber
                                        auch ein schwerer Verlauf mit Schmerzen
                                        und Fieber möglich. Das Risiko an
                                        Durchfall zu erkranken ist von Land zu
                                        Land unterschiedlich.
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        padding: "0"
                                      }}
                                    >
                                      <p>
                                        In unserer Karte können Sie nachsehen,
                                        wie hoch die Gefahr eines
                                        Reisedurchfalls im jeweiligen Land ist.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <h2>Länder mit erhöhtem Reisedurchfallrisiko:</h2>
                              <p>
                                Zu den Risikoländern gehören insbesondere Länder
                                in Süd-Amerika, Afrika und Asien. So sollten Sie
                                in Ländern wie Brasilien, Kuba, Ägypten oder
                                Thailand besonders achtsam sein, was Sie zu sich
                                nehmen. Die Regel „Cook it, boil it, peel it or
                                forget it” (Koch es, brat’ es, schäl’ es oder
                                vergiss es), kann Ihnen dabei helfen, das
                                Durchfallrisiko zu minimieren. Zudem sollten Sie
                                besonders bei Trinkwasser vorsichtig sein: Zum
                                Trinken oder Zähneputzen greifen Sie
                                sicherheitshalber auf abgekochtes oder im besten
                                Fall auf abgefülltes Wasser zurück.
                              </p>
                              <h2>
                                Länder mit mittlerem Reisedurchfallrisiko:
                              </h2>
                              <p>
                                Ein mittleres Reisedurchfallrisiko herrscht in
                                Ost- und Südeuropäischen Ländern, Russland sowie
                                in südlichen Teilen Süd-Amerikas und in
                                Süd-Afrika. Auch in diesen Ländern entsprechen
                                die Hygiene- und Trinkwasserqualität meist nicht
                                unseren Standards. Eine Erkrankung an Durchfall
                                ist deshalb auch in Ländern wie Spanien,
                                Italien, Kroatien genau wie in Chile und
                                Argentinien durchaus möglich.
                              </p>
                              <h2>
                                Länder mit niedrigerem Reisedurchfallrisiko:
                              </h2>
                              <p>
                                Grundsätzlich ist das Reisedurchfallrisiko in
                                den westlichen Industrieländern am niedrigsten.
                                Weniger Sorgen müssen Sie sich also in Ländern
                                in Nord-Amerika, West-Europa sowie in Australien
                                und Neuseeland machen.&nbsp; In den USA, Kanada,
                                Frankreich, Schweden oder Australien herrschen
                                vergleichbare Hygiene- und Trinkwasserstandards
                                wie in der Schweiz. Aber selbst in diesen
                                Ländern ist immer ein gewisses
                                Reisedurchfallrisiko vorhanden, weshalb es sich
                                lohnt stets gegen Reisedurchfall gewappnet zu
                                sein.
                              </p>
                              <h2>Kritische Reisezeiten</h2>
                              <p>
                                Achten Sie bei der Planung Ihrer Fernreise auf
                                die Reisezeit. Das Reisedurchfallrisiko kann
                                sich je nach Jahreszeit erhöhen. In Ländern wie
                                Thailand, Indonesien oder Mexiko steigt
                                beispielsweise während der jeweils
                                unterschiedlichen Monsunzeiten das Risiko für
                                akuten Durchfall. Aufgrund hoher Wassermengen
                                und einer oftmals damit einhergehenden
                                Überlastung der Abwassersysteme verschlechtern
                                sich die Hygienestandards dann weiter. In
                                Monaten, in denen es besonders heiss ist, kann
                                das Reisedurchfallrisiko ebenfalls steigen.
                              </p>
                              <h2>Kritische Reisearten</h2>
                              <p>
                                Je nach Art der Reise ist das Risiko, an akutem
                                Durchfall zu erkranken, unterschiedlich hoch.
                                Generell gilt, dass Trekking- und
                                Rucksackurlaube mit einem erhöhten
                                Durchfallrisiko verbunden sind, weil es
                                schwieriger ist, dauerhaft hohe Hygienestandards
                                zu finden, z.B. wenn häufig an Strassenständen
                                gegessen wird.
                              </p>
                              <p>
                                Kreuzfahrten bringen ebenfalls ein erhöhtes
                                Durchfallrisiko mit sich. Aufgrund der Dichte an
                                Menschen haben Durchfallerreger leichtes Spiel,
                                sich auf dem Schiff zu verbreiten.
                              </p>
                              <p>
                                Obwohl das Durchfallrisiko bei Pauschalurlauben
                                tendenziell ein geringeres ist, sollten Sie auch
                                bei dieser Art von Reise vorsichtig sein. Die
                                Hygienestandards können je nach Hotel stark
                                variieren. Häufige Gefahrenquellen sind Buffets,
                                Pool- und Sanitäranlagen.
                              </p>
                              <p>&nbsp;</p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
